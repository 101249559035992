import React from 'react'
import Slider from 'react-slick'
import {
    Container,
    Grid,
    Header,
    Image,
    Segment,
    Divider,
} from "semantic-ui-react"
import ntu_as_logo from '../images/ntu_as_logo.png'

import logo1 from '../images/About/1.png'
import logo2 from '../images/About/2.png'
import logo3 from '../images/About/3.png'
import logo4 from '../images/About/4.png'
import logo5 from '../images/About/5.png'

const About = () => {
    return (
        <div>
        <Slider className="slide">
            <Segment inverted vertical id="home_segment"/>
        </Slider>
        
        <Container>
          <Divider horizontal>
            <Header as='h1' id='learn_more'>
              Overview
            </Header>
          </Divider>
        
          <p>&emsp;&emsp;Artificial Intelligence of Things (AIoT) is an emerging technology that combines artificial intelligence (AI) and the Internet of Things (IoT). It is one of the most promising scientific and technological trends today. In recent years, with breakthroughs in computer storage space and computing performance, coupled with big data analysis, inference, and learning, AI has become the focus and is widely adopted in many fields, such as finance, manufacturing, retail, and medical industries. IDC's Worldwide Artificial Intelligence Spending Guide report shows that the total global AI spending will increase more than twice in the next four years, from $50.1 billion in 2020 to more than $110 billion in 2024. According to PWC's report, by 2030, the global AI technology output value will increase to 6.6 trillion U.S. dollars. AI applications will generate 9.1 trillion U.S. dollars, bringing 14% growth to the worldwide GDP. The potential for business opportunities is promising. </p>
          <p>&emsp;&emsp;On the other hand, IoT upgrades communications from the traditional "human-to-human" and "human-to-machine" communication and interactions to the intelligent "machine-to-machine," which connects various digital devices to the Internet and transmits all kinds of information sensed to each other. Through machine learning and data mining technologies, we can further discover valuable knowledge and ubiquitously provide various services in people’s lives. According to the research of McKinsey Management Consultants, the global application output value of IoT will reach 11.1 trillion U.S. dollars in 2025. IDC also assesses that the data volume of IoT devices from 2018 to 2025 will grow at an average annual growth rate of 28.7% and reach 79.4ZB in 2025. For the applications of AIoT, Frost & Sullivan, a world-renowned business consulting firm, estimates that the global output value of smart cities will reach a market size of 2 trillion U.S. dollars by 2025. </p>
          <p>&emsp;&emsp;The development of IC chips, wireless transmission, and data storage technologies in Taiwan has gradually matured in recent years. Therefore, industries, government administration, and academia have begun to attach importance to the value of integrating AIoT with interdisciplinary fields. The development of AIoT technology not only has high research value in academics but also plays a pivotal role in the progress of various areas in Taiwan, such as technology industries, government efficiency, biomedical science and technology, health management, transportation construction, agricultural technology, as well as cultural and creative industries, and the upgrading of international competitiveness. Therefore, there is an urgent need for AIoT talents and technologies. </p>
          <Divider horizontal>
            <Header as='h1'>
            The TIGP Program on AIoT
            </Header>
          </Divider>
          <Container textAlign='center'>
          <Image.Group size='massive'>
            <Image  src={ntu_as_logo}/>
          </Image.Group>
          </Container>
          <div id="menu_space"/>
          
          <p>&emsp;&emsp;The TIGP on Artificial Intelligence of Things (AIoT) is a program established jointly by National Taiwan University (NTU) and Academia Sinica. AIoT has recently been a novel and rapid-developing application and technology. The TIGP AIoT program can cultivate domestic and international talents in related areas, enhance innovative potential, and raise the level of academic research. NTU and Academia Sinica will co-play leading and supervisory roles and provide research resources and equipment. In addition, the participating faculties of these institutes will be jointly responsible for teaching activities, supervising research, and guiding international graduate students.</p>
          <p>&emsp;&emsp;This TIGP program expects to recruit prominent young domestic and international students. In the scope of AIoT, this program prioritizes computer science and engineering and takes mathematics as an auxiliary to provide students with training across various fields. The curricula will delve into a wide variety of research areas of emphasis in computer science, electrical engineering, and communication, including networking and communications, signal processing, embedded IoT systems, cloud computing, mobile edge computing, big data systems, machine learning, data mining, multivariate data analysis, business models, privacy and security, and multimedia.</p>
          <p>&emsp;&emsp;In TIGP on Artificial Intelligence of Things, the cooperating institutes will provide the required laboratory equipment and instruments for academic and educational purposes. This specific TIGP program will simultaneously focus on theory and practice to help students build a sound theoretical basis and foster technical abilities to solve practical problems in various applications. With the resources and varied expertise of the researchers from the cooperating institutes, this program provides students with an excellent educational opportunity to cultivate their research capabilities in interdisciplinary fields.</p>
          <Divider horizontal>
            <Header as='h1'>
              Research Topics
            </Header>
          </Divider>

          <Segment vertical>
            <Grid stackable>
              <Grid.Column width={10}>
                <Header as="h1">
                 Intelligent Healthcare
                </Header>
                <p>
                &emsp;&emsp;Epidemics and pandemics, such as COVID-19, MERS, and H1N1, significantly impact public health, society, and the economy. Before any treatment becomes available or vaccines are fully deployed, it is essential to containing the epidemics by contact tracing via social networks, communication networks, and cell broadcast to ensure case isolation and quarantine, and avoid swarm infection. Google and Apple announced a joint effort to leverage Bluetooth to reduce the spread of COVID-19 by tracing user privacy and security in the core design. Google Sodar uses Augmented Reality (AR) technology to visualize a two-meter radius ring around people to help maintain social distancing. Many innovative mobile applications, such as Aarogya Setu and 1point5, also help people maintain social distancing by measuring the Bluetooth signal of nearby mobile phones to monitor the proximity of mobile users with IoT. Therefore, these epidemics have motivated the urgency and importance of studying the research area of epidemiological data collection, surveillance, contact tracing, and potential source identification with the help of AIoT for infectious disease containment.
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <Image src={logo1} />
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment vertical>
            <Grid stackable>
              <Grid.Column width={6}>
                <Image src={logo2} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as="h1">
                  Machine Learning in AIoT
                </Header>
                <p>
                &emsp;&emsp;A large-scale IoT system can be developed via integrating multiple IoT subsystems in various innovative applications, producing vast amounts of collected or real-time data. Intelligent and efficient data processing plays a crucial role in effectively using the information generated from these data. Machine learning techniques enable the analysis and utilization of the data for tackling problems or making decisions. Regarding the massive amount of data under IoT systems, machine learning pays more attention to actively learning from the data and extracting features, discovering knowledge that humans hardly observe, and providing feedback to improve the algorithms to enhance performance. With the help of vast amounts of data, the effectiveness of machine learning has made revolutionary progress. In recent years, it has gradually moved from theory to reality. Some areas, such as face recognition, object recognition, voice recognition, and acoustic event detection, have demonstrated their practical value. Integrated with the IoT, it is foreseeable that there will be more breakthrough developments in the industry and academia in the future for popular multi-task learning, active learning, online learning, transfer learning, and ensemble learning. Nevertheless, there are still many important issues that require further studies, such as complexity, efficiency, scalability, accuracy, and robustness regarding machine learning in innovative AIoT systems and applications.
                </p>
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment vertical>
            <Grid stackable>
              <Grid.Column width={10}>
                <Header as="h1">
                Big Data in AIoT
                </Header>
                <p>
                &emsp;&emsp;AIoT data, collected from various sensors over long periods, is a type of big data. Typically, the main characteristics of big data include 1) velocity, denoting the speed of data production and processing to extract valuable insights, 2) volume, implying the enormous quantities of data throughput per second, 3) variety, specifying various formats of data such as documents, videos, and logs, and 4) veracity, indicating the data quality factors. In the domain of AIoT, distributed systems are the most feasible solutions to process, store, analyze, and compute the large amount of data collected by IoT. In addition to being the theoretical foundation and core technology of cloud computing, distributed systems are also the key technology for research centers and governments. Improving the distributed systems' performance and storage capacity to fulfill the future demand of AIoT is a crucial research direction for both industry and academia.
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <Image src={logo3} />
              </Grid.Column>
            </Grid>
          </Segment>
          <Segment vertical>
            <Grid stackable>
              <Grid.Column width={6}>
                <Image src={logo4} />
              </Grid.Column>
              <Grid.Column width={10}>
                <Header as="h1">
                Blockchain and Mobile Edge Computing (MEC)
                </Header>
                <p>
                &emsp;&emsp;With the emergence of 5G/6G, Internet of Things (IoT), and Artificial Intelligence (AI), AIoT has emerged to fulfill various requirements of intelligence, flexibility, agility, and ubiquitous accessibility of cyberspace. However, the deficiencies of the conventional centralized IoT architecture, such as single-point failure, data privacy, security, and robustness, pose heavy restrictions. In recent years, blockchain has become an open, cryptographic, and decentralized database, maintaining distributed ledgers accessible but immutable for everyone. Moreover, the rapid-developing Mobile Edge Computing (MEC) has enabled ubiquitous and real-time AIoT services by pushing cloud services from the core to the edges of network systems, which are closer to AIoT devices. Therefore, blockchain and MEC are innovative technologies to bridge these gaps by enabling novel AIoT applications and services via secure, reliable, flexible, and intelligent devices and systems along with corresponding digital value and economics. AIoT offers innovative solutions with the assistance of blockchain and MEC to tackle many challenges, such as decentralization service schemes, service virtualization and flexibility, reliable management and maintenance, resource optimization, etc.
                </p>
              </Grid.Column>
              
            </Grid>
          </Segment>
          <Segment vertical>
            <Grid stackable>
              <Grid.Column width={10}>
                <Header as="h1">
                Integration of AIoT and Next Generation Networks
                </Header>
                <p>
                &emsp;&emsp;As AI-on-chips have attained high performance and power efficiency, AI services are facilitated to make things more intelligent, where each object has a different perspective on the surroundings, and all information is locally combined and analyzed to form a more comprehensive understanding of how and why the events happen. These connected AI-enabled things are finding their way into vehicles (smart cars), urban areas (smart cities), and infrastructure (smart grid). AIoT automatically creates and maintains social relations among intelligent vehicles and objects to deal with the possibly unpredictable and intense environmental changes due to user mobility, network congestion, etc. Moreover, AIoT can also detect and analyze human behaviors (e.g., social interactions, purchase logs, and traveling trajectories) with social networking websites to obtain more accurate results. Accordingly, integrating AI, IoT, and social networks to provide instantaneous, robust, and diverse user services is imperative. As air (e.g., UAVs) and ground vehicles are two vital network intermediaries in the next-generation network (i.e., 6G), it is envisaged that Air-Ground Integrated sociaL nEtwork (AGILE) is a prevailing trend, where Human Social Network (HSN) reveals user behaviors, sentiments, and opinions to benefit data-driven decision-making with AI, while AIoT provides and analyzes versatile environmental sensing to improve service quality.
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <Image src={logo5} />
              </Grid.Column>
            </Grid>
          </Segment>
        </Container>
        </div>
    )
}

export default About