import React from 'react'
import Slider from 'react-slick'
import {
    Table,
    Container,
    Header,
    Segment,
    Divider,
} from "semantic-ui-react"

const Academic = () => {
    return (
        <div>
            <Slider className="slide">
                <div id="academic_background">
                    <Segment inverted vertical id="academic_segment"/>
                </div>
            </Slider>
            <Container>
                <Divider horizontal>
                    <Header as='h1'>
                    Course Schedule for Fall 2024
                    </Header>
                </Divider>
                <p>For more course information, visit <a href="https://nol2.aca.ntu.edu.tw/nol/guest/index.php">here</a>.</p>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Subject</Table.HeaderCell>
                            <Table.HeaderCell>Credit</Table.HeaderCell>
                            <Table.HeaderCell>Chair</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Place</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><a href="https://docs.google.com/document/d/1eKR7HTg0bJSJU7rrpO-QB6djdYrNrLEc/edit?usp=sharing&ouid=101873118496380543087&rtpof=true&sd=true">Seminar</a></Table.Cell>
                            <Table.Cell>0</Table.Cell>
                            <Table.Cell>Dr. Ding-Yong Hong <br/> Dr. Hsiang-Yun Cheng <br/> Dr. Chien-Yao Wang </Table.Cell>
                            <Table.Cell>Fri 1400-1600</Table.Cell>
                            <Table.Cell>N106, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://nol2.aca.ntu.edu.tw/nol/coursesearch/print_table.php?course_id=946%20U0130&class=&dpt_code=9490&ser_no=61504&semester=113-1&lang=EN">Frontiers of Artificial Intelligence of Things</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Chih-Yu Wang</Table.Cell>
                            <Table.Cell>Tue 1320-1620</Table.Cell>
                            <Table.Cell>Room 117, MK Innovation Hall, NTU</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://nol2.aca.ntu.edu.tw/nol/coursesearch/print_table.php?course_id=922%20U4890&class=&dpt_code=9490&ser_no=20854&semester=113-1&lang=EN">Mobile and Pervasive Intelligence</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Fang-Jing Wu</Table.Cell>
                            <Table.Cell>Tue 1320-1620</Table.Cell>
                            <Table.Cell>Room 107, CSIE Building, NTU</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://nol2.aca.ntu.edu.tw/nol/coursesearch/print_table.php?course_id=946%20U0110&class=&dpt_code=9490&ser_no=20646&semester=113-1&lang=EN">Introduction to Artificial Intelligence of Things and its Applications</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Ling-Jyh Chen </Table.Cell>
                            <Table.Cell>Wed 1320-1620</Table.Cell>
                            <Table.Cell>N106, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://tigpsnhcc.iis.sinica.edu.tw/download/HCC_Fall_2024.pdf">Human-Centered Computing<br/>(SNHCC program shared course)</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Jun-Cheng Chen</Table.Cell>
                            <Table.Cell>Wed 0900-1200</Table.Cell>
                            <Table.Cell>N107, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://tigpsnhcc.iis.sinica.edu.tw/download/SN_Fall_2024.pdf">Introduction to Social Networks<br/>(SNHCC program shared course)</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Yi-Shin Chen</Table.Cell>
                            <Table.Cell>Thu 0900-1200</Table.Cell>
                            <Table.Cell>N107, IIS, AS</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>           

                <Divider horizontal>
                    <Header as='h1'>
                    Graduation Requirement
                    </Header>
                </Divider>
                <p>&emsp;&emsp;Research in AIoT Program can be categorized into four fields which correspond to the institutes under the College of Electrical Engineering and Computer Science, National Taiwan University. Students shall decide their research interests and follow the graduation requirement based on the graduate institutes below.</p>
                <p>&emsp;&emsp;Note: All application forms (e.g., qualification assessment, thesis proposal review, and oral defense) and questions (e.g., course credits) should be emailed to <a href="mailto:tigpaiot@gate.sinica.edu.tw">TIGP AIoT office</a> instead of respective institutes.</p>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='2'></Table.HeaderCell>
                            <Table.HeaderCell ><a href='https://www.ee.ntu.edu.tw/graduate/english/index.php/program/requirements'>Graduate Institute of Electrical Engineering (EE)</a></Table.HeaderCell>
                            <Table.HeaderCell ><a href='https://comm.ntu.edu.tw/en/'>Graduate Institute of Communication Engineering (GICE)</a></Table.HeaderCell>
                            <Table.HeaderCell ><a href='https://www.inm.ntu.edu.tw/main.php'>Graduate Institute of Networking and Multimedia (GINM)</a></Table.HeaderCell>
                            <Table.HeaderCell ><a href='https://www.csie.ntu.edu.tw/main.php'>Graduate Institute of Computer Science and Information Engineering (CSIE)</a></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell rowSpan='5'>Course Requirements: <br/>at least 18 credits with approval from the advisor, excluding PhD Thesis, Special Project, Seminar, Udergraduate Course or General Course</Table.Cell>
                            <Table.Cell>PhD Thesis (0 credit)</Table.Cell>
                            <Table.Cell>At least in the last semester</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Special Project (1 credit)</Table.Cell>
                            <Table.Cell rowSpan='2'>Every semester during the study except for the graduation semester</Table.Cell>
                            <Table.Cell>Every semester during the study (Those who have completed 6 semesters can waive one semester with approval from the advisor)</Table.Cell>
                            <Table.Cell>Every semester before taking PhD Thesis</Table.Cell>
                            <Table.Cell>Every semester</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Seminar (0 credit)</Table.Cell>
                            <Table.Cell>In the previous 6 semesters, one of which can be replaced with the seminar imparted by the other institute under the College of Electrical Engineering and Computer Science with approval from the advisor.</Table.Cell>
                            <Table.Cell>4 semesters</Table.Cell>
                            <Table.Cell>4 semesters</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Specific Field/Group</Table.Cell>
                            <Table.Cell>Control Systems, Power Systems, Computer Science, Information Security</Table.Cell>
                            <Table.Cell>Electromagnetics (EM), Communication and Signal Processing (CSP), Data Science (DS)</Table.Cell>
                            <Table.Cell>Multi-Media Technology, Networking Technology, Systems and Application, Data Science and AI</Table.Cell>
                            <Table.Cell>Systems and Network, Multimedia and Contents, Data Science and AI, Bioinformatics and Theory</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='5'>In addition to the above-mentioned course, it's mandatory for students to complete Research Ethics Education course within one year after enrollment.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='4'>Qualification Assessment</Table.Cell>
                            <Table.Cell>Measure</Table.Cell>
                            <Table.Cell>Pass the review and required exam depending on the group</Table.Cell>
                            <Table.Cell>Pass the required exam depending on the group. (<a href='https://comm.ntu.edu.tw/en/Doctoral_Qualification_Exam.php'>link</a>) <br/>The exam will be held once each semester, detailed schedule is subject to GICE.</Table.Cell>
                            <Table.Cell>Pass 4 out of the 7 core courses (i.e., Computer Graphics, Digital Image Processing, Information Theory and Coding Techniques, Advanced Computer Network, Advanced Operating System, Information Retrieval and Extraction, Artificial Intelligence).<br/> The exam will be held 4 weeks after the beginning of each semester. Apply for the exam through email before the semester gets started.</Table.Cell>
                            <Table.Cell>1. Fundamental courses <br/>2. Research ability assessments <br/>(<a href='https://tigpaiot.iis.sinica.edu.tw/#/form_link'>link</a>)</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='2'>Deadline</Table.Cell>
                            <Table.Cell>within 4 semesters<br/>(excluding any leaves of absence)</Table.Cell>
                            <Table.Cell>within 5 semesters<br/>(excluding any leaves of absence)</Table.Cell>
                            <Table.Cell>1. Students need to take the exam within one year after enrollment. <br/>2. Complete within 6 semesters (including any leaves of absense)</Table.Cell>
                            <Table.Cell>within 3 years<br/> (including any leaves of absense)</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan='4'>All the deadline can be deferred for one year for students in AIoT Program</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Exemption</Table.Cell>
                            <Table.Cell>Either by taking courses (approved by advisor) or publishing papers, should apply beforehand and get the committee's approval (<a href="https://tigpaiot.iis.sinica.edu.tw/#/form_link">link</a>)</Table.Cell>
                            <Table.Cell>By taking the courses (approved by advisor)</Table.Cell>
                            <Table.Cell>1. By taking courses (approved by advisor)<br/>2. By publishing papers<br/>3. Through system implementation<br/>(<a href='https://tigpaiot.iis.sinica.edu.tw/#/form_link'>link</a>)</Table.Cell>
                            <Table.Cell>By taking the courses (approved by advisor)</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>PhD Candidate</Table.Cell>
                            <Table.Cell colSpan='5'>A doctoral student shall meet the following two conditions to be nominated as a Ph.D. candidate:<br/>1. Completing all the required coursework for the doctoral degree<br/>2. 2. Passing the doctoral candidacy qualifying examination (i.e., Qualification Assessment)</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='3'>Thesis Proposal Review</Table.Cell>
                            <Table.Cell>Prerequisite</Table.Cell>
                            <Table.Cell>Pass Qualification Assessment</Table.Cell>
                            <Table.Cell>Pass Qualification Assessments</Table.Cell>
                            <Table.Cell>1. Fulfill the Qualification Assessment and Course Requirements <br/>2. Get the advisor's approval</Table.Cell>
                            <Table.Cell>The next semester after fulfilling the PhD candidate requirement</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Measure</Table.Cell>
                            <Table.Cell>Present the proposal</Table.Cell>
                            <Table.Cell>By research proposal or by paper credits</Table.Cell>
                            <Table.Cell>Present the proposal</Table.Cell>
                            <Table.Cell>Present the proposal</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Deadline</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell>Within 4 semesters after passing the Qualification Assessments</Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell rowSpan='3'>PhD Degree Oral Defense</Table.Cell>
                            <Table.Cell colSpan='5'>Thesis Proposal Review and PhD Degree Oral Defense cannot be held in the same semester.</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Prerequisite</Table.Cell>
                            <Table.Cell>1. Complete Thesis Proposal Review.<br/>2. Apply for the review.</Table.Cell>
                            <Table.Cell>Complete Thesis Proposal Review.</Table.Cell>
                            <Table.Cell>1. Complete Thesis Proposal Review.<br/>2. Get the advisor's approval and apply for the review</Table.Cell>
                            <Table.Cell>Complete Thesis Proposal Review. </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Measure</Table.Cell>
                            <Table.Cell>Visit EE website</Table.Cell>
                            <Table.Cell>Visit GICE website</Table.Cell>
                            <Table.Cell>Visit GINM website</Table.Cell>
                            <Table.Cell>Visit CSIE website</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>

                <Divider horizontal>
                    <Header as='h1'>
                    Course Schedule for Fall 2023
                    </Header>
                </Divider>
                <p>For more course information, visit <a href="https://nol.ntu.edu.tw/nol/coursesearch/index.php?lang=EN">here</a>.</p>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Subject</Table.HeaderCell>
                            <Table.HeaderCell>Credit</Table.HeaderCell>
                            <Table.HeaderCell>Chair</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Place</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><a href="https://drive.google.com/file/d/1R2NoARkdon1dWsWWFVsClrNhVXMdYeRB/view?usp=sharing">Seminar</a></Table.Cell>
                            <Table.Cell>0</Table.Cell>
                            <Table.Cell>Dr. Ding-Yong Hong</Table.Cell>
                            <Table.Cell>Fri 1400-1600</Table.Cell>
                            <Table.Cell>N106, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://tigpsnhcc.iis.sinica.edu.tw/download/HCC_Fall_2023.pdf">Human-Centered Computing<br/>(SNHCC program shared course)</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Jun-Cheng Chen</Table.Cell>
                            <Table.Cell>Wed 0900-1200</Table.Cell>
                            <Table.Cell>N107, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://drive.google.com/file/d/1QWI10n2gbCvohAC3XN7sPwoWNV45bLzm/view?usp=sharing">Introduction to Artificial Intelligence of Things<br/>and its Applications</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Ling-Jyh Chen</Table.Cell>
                            <Table.Cell>Thu 1420-1720</Table.Cell>
                            <Table.Cell>N106, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://tigpsnhcc.iis.sinica.edu.tw/download/SN_Fall_2023.pdf">Social Networks<br/>(SNHCC program shared course)</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Yi-Shin Chen Chen</Table.Cell>
                            <Table.Cell>Thu 0900-1200</Table.Cell>
                            <Table.Cell>N107, IIS, AS</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Divider horizontal>
                    <Header as='h1'>
                    Course Schedule for Spring 2024
                    </Header>
                </Divider>
                <p>For more course information, visit <a href="https://nol.ntu.edu.tw/nol/coursesearch/index.php?lang=EN">here</a>.</p>
                <Table celled structured>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Subject</Table.HeaderCell>
                            <Table.HeaderCell>Credit</Table.HeaderCell>
                            <Table.HeaderCell>Chair</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Place</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><a href="https://drive.google.com/file/d/17L7sWYKITBy_T3Ver2fWv-DZL4qSQ15z/view?usp=sharing">Seminar</a></Table.Cell>
                            <Table.Cell>0</Table.Cell>
                            <Table.Cell>Dr. Ding-Yong Hong</Table.Cell>
                            <Table.Cell>Fri 1400-1600</Table.Cell>
                            <Table.Cell>N106, IIS, AS</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://nol2.aca.ntu.edu.tw/nol/coursesearch/print_table.php?course_id=946%20U0130&class=&dpt_code=9490&ser_no=64462&semester=112-2&lang=EN">Frontiers of Artificial Intelligence of Things</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Chih-Yu Wang</Table.Cell>
                            <Table.Cell>Tue 1320-1620</Table.Cell>
                            <Table.Cell>Room503, Xingsheng Lecture Building, NTU</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><a href="https://tigpsnhcc.iis.sinica.edu.tw/course.html">Natural Language Processing and Information Retrieval in Social Networks<br/>(SNHCC program shared course)</a></Table.Cell>
                            <Table.Cell>3</Table.Cell>
                            <Table.Cell>Dr. Hen-Hsen Huang</Table.Cell>
                            <Table.Cell>Thu 1400-1700</Table.Cell>
                            <Table.Cell>N107, IIS, AS</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>           

                </Container>
        </div>
    )
}

export default Academic